<template>
  <div class="article-box" v-loading="loading">
    <h1 style="margin-bottom: 20px;">分支机构信息维护</h1>
    <el-descriptions :column="2" style="padding: 20px 20px 0; background: #fff;">
      <el-descriptions-item label="分支机构名称">{{
        this.list.nickName
      }}</el-descriptions-item>
      <el-descriptions-item label="成立时间">{{
        this.list.openDate
      }}</el-descriptions-item>
      <el-descriptions-item label="换届时间"
        >{{ this.list.electionDate }}
      </el-descriptions-item>
      <el-descriptions-item label="下次换届时间">{{
        this.list.nextElectionDate
      }}</el-descriptions-item>
      <el-descriptions-item label="协会分管副会长"
        >{{ this.list.xiehuiFgfhzName }}
      </el-descriptions-item>
      <el-descriptions-item label="联系方式"
        >{{ this.list.xiehuiFgfhzTel }}
      </el-descriptions-item>
    </el-descriptions>
    <div v-if="show" >
      <el-descriptions :column="2" style="padding: 0 20px 20px; background: #fff;">
        <el-descriptions-item label="现任会长（主任）">
          {{ this.list.huizhangName || "-" }}</el-descriptions-item
        >
        <el-descriptions-item label="联系方式">
          {{ this.list.huizhangTel || "-" }}</el-descriptions-item
        >
        <el-descriptions-item label="常务副会长（主任）"
          >{{ this.list.changwufhzName || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="联系方式">
          {{ this.list.changwufhzTel || "-" }}</el-descriptions-item
        >
        <el-descriptions-item label="秘书长"
          >{{ this.list.mishuzName || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="联系方式">
          {{ this.list.mishuzTel || "-" }}</el-descriptions-item
        >
        <el-descriptions-item label="常用联系人"
          >{{ this.list.contectName || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="联系方式"
          >{{ this.list.contectTel || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="财务联系人"
          >{{ this.list.financeContectName || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="联系方式"
          >{{ this.list.financeContectTel || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="办公地址"
          >{{ this.list.address || "-" }}
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="primary" @click.native.prevent="sure" style="margin-top: 20px;">修 改</el-button>
    </div>

    <div v-else>
      <el-descriptions :column="2" style="padding: 0px 20px 20px; background: #fff;">
        <el-descriptions-item label="现任会长（主任）">
          <el-input type="text" v-model="huizhangName"
        /></el-descriptions-item>
        <el-descriptions-item label="联系方式">
          <el-input type="text" v-model="huizhangTel"
        /></el-descriptions-item>
        <el-descriptions-item label="常务副会长（主任）"
          ><el-input type="text" v-model="changwufhzName" />
        </el-descriptions-item>
        <el-descriptions-item label="联系方式"
          ><el-input type="text" v-model="changwufhzTel" />
        </el-descriptions-item>
        <el-descriptions-item label="秘书长">
          <el-input type="text" v-model="mishuzName"
        /></el-descriptions-item>
        <el-descriptions-item label="联系方式"
          ><el-input type="text" v-model="mishuzTel" />
        </el-descriptions-item>
        <el-descriptions-item label="常用联系人"
          ><el-input type="text" v-model="contectName" />
        </el-descriptions-item>
        <el-descriptions-item label="联系方式"
          ><el-input type="text" v-model="contectTel" />
        </el-descriptions-item>
        <el-descriptions-item label="财务联系人"
          ><el-input type="text" v-model="financeContectName" />
        </el-descriptions-item>
        <el-descriptions-item label="联系方式">
          <el-input type="text" v-model="financeContectTel"
        /></el-descriptions-item>
        <el-descriptions-item label="办公地址"
          ><el-input type="text" v-model="address" />
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="primary" style="margin-top: 20px;" @click.native.prevent="fore(1)"
        >确 定</el-button
      >
      <el-button type="primary" @click.native.prevent="fore(2)"
        >取 消</el-button
      >
    </div>
    <el-dialog
      title="确定修改"
      :visible="work4"
      width="600px"
      :close-on-click-modal="false"
      :show-close="true"
      @close="work4Clear"
    >
      确定修改以上内容
      <span slot="footer">
        <el-button plain @click="work4Clear">取消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click.native.prevent="work4Submit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { institutionLiae, institutionup } from "@/http/api/InitiationApi";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      show: true,
      loading: false,
      list: [],
      work4: false,
      //   sure: true,
      //   fore: false,
      address: "", //string, optional): 办公地址 ,
      changwufhzName: "", //string, optional): 常务副会长 ,
      changwufhzTel: "", //string, optional): 常务副会长联系方式 ,
      contectName: "", //string, optional): 常用联系人 ,
      contectTel: "", //string, optional): 常用联系人方式 ,

      financeContectName: "", //string, optional): 财务联系人 ,
      financeContectTel: "", //string, optional): 财务联系人方式 ,
      huizhangName: "", //string, optional): 现任会长 ,
      huizhangTel: "", //string, optional): 现任会长联系方式 ,
      id: "", //string, optional): 分支机构id ,
      loginName: "", //string, optional): 分支机构代码 ,
      mishuzName: "", //string, optional): 秘书长 ,
      mishuzTel: "", //string, optional): 秘书长联系方式 ,
      electionDate: "2022-03-25 00:00", //string, optional): 换届时间 ,
      nextElectionDate: "2022-03-25 00:00", //string, optional): 下次换届时间 ,
      nickName: "信专委", //string, optional): 分支机构名称 ,
      openDate: "2022-03-23 00:00", //string, optional): 成立时间 ,
      xiehuiFgfhzName: "ryhtyhtyhyt", //string, optional): 协会分管副会长 ,
      xiehuiFgfhzTel: "17631686742", //string, optional): 协会分管副会长联系电话
    };
  },

  methods: {
    work4Submit() {
      institutionup({
        address: this.address, // optional): 办公地址 ,
        changwufhzName: this.changwufhzName, // optional): 常务副会长 ,
        changwufhzTel: this.changwufhzTel, // optional): 常务副会长联系方式 ,
        contectName: this.contectName, // optional): 常用联系人 ,
        contectTel: this.contectTel, // optional): 常用联系人方式 ,
        financeContectName: this.financeContectName, // optional): 财务联系人 ,
        financeContectTel: this.financeContectTel, // optional): 财务联系人方式 ,
        huizhangName: this.huizhangName, // optional): 现任会长 ,
        huizhangTel: this.huizhangTel, // optional): 现任会长联系方式 ,
        mishuzName: this.mishuzName, // optional): 秘书长 ,
        mishuzTel: this.mishuzTel, // optional): 秘书长联系方式 ,
        // nextElectionDate: this.nextElectionDate, // optional): 下次换届时间 ,
        // openDate: this.openDate, // optional): 成立时间 ,
        // unitName: this.unitName, // optional): 分支机构名称 ,
        // xiehuiFgfhzName: this.xiehuiFgfhzName, // optional): 协会分管副会长 ,
        // xiehuiFgfhzTel: this.xiehuiFgfhzTel, // optional): 协会分管副会长联系电话
        // electionDate: this.electionDate, // optional): 换届时间 ,
        id: this.id, // optional): 分支机构Id ,
      }).then((res) => {
        if (res.code === "000") {
          this.show = !this.show;
          this.work4 = false;
          this.$message({
            type: "success",
            message: "修改成功！！！",
          });
          this.getList();
        }
      });
    },
    work4Clear() {
      this.work4 = false;
    },
    sure() {
      this.show = !this.show;
    },
    fore(num) {
      if (num === 1) {
        this.work4 = true;
      } else {
        this.show = !this.show;
        this.work4 = false;
      }
    },
    getList() {
      institutionLiae().then((res) => {
        if (res.code === "000") {
          this.list = res.result;
          this.id = res.result.id;
          this.nextElectionDate = res.result.nextElectionDate;
          this.openDate = res.result.openDate;
          this.unitName = res.result.unitName;
          this.xiehuiFgfhzName = res.result.xiehuiFgfhzName;
          this.xiehuiFgfhzTel = res.result.xiehuiFgfhzTel;
          this.address = res.result.address; // optional): 办公地址 ,
          this.changwufhzName = res.result.changwufhzName; // optional): 常务副会长 ,
          this.changwufhzTel = res.result.changwufhzTel; // optional): 常务副会长联系方式 ,
          this.contectName = res.result.contectName; // optional): 常用联系人 ,
          this.contectTel = res.result.contectTel; // optional): 常用联系人方式 ,
          this.financeContectName = res.result.financeContectName; // optional): 财务联系人 ,
          this.financeContectTel = res.result.financeContectTel; // optional): 财务联系人方式 ,
          this.huizhangName = res.result.huizhangName; // optional): 现任会长 ,
          this.huizhangTel = res.result.huizhangTel; // optional): 现任会长联系方式 ,
          this.mishuzName = res.result.mishuzName; // optional): 秘书长 ,
          this.mishuzTel = res.result.mishuzTel; // optional): 秘书长联系方式 ,
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang='scss' scoped>
</style>